import { useAccount } from 'wagmi';
import WalletAccount from './wallet-account';
import WalletOptions from './wallet-options';

function WalletConnect() {
  const { isConnected } = useAccount();
  if (isConnected) return <WalletAccount />;
  return <WalletOptions />;
}

export default WalletConnect;
